.menu {
  position: absolute;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  background-color: white;
  z-index: 11;
}

.menu-container {
  margin: auto;
  padding-top: 26vh;
  width: 80vw;
  max-width: 400px;
  text-align: center;
  font-size: 3vh;
}

.menu-language {
  color: #d3d3d3;
}

.menu-items, a {
  color: #242424;
  margin-bottom: 0.9vh;
}
.menu-items:hover, .menu-items.active, .facebook:hover, .instagram:hover {
  color: #dc8960;
}

.menu-social {
  padding-top: 18%;
  margin: auto;
  width: 80vw;
  max-width:400px;
  font-size: 3vh;
}

.facebook, .instagram {
  color: #242424;
  text-decoration: none;
}

.facebook {
  margin-right: 20px;
}
