.publications {
  position: absolute;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  background-color: white;
  font-family: arial;
  font-size: 1.6vh;
}

.publications-wrapper {
  margin: auto;
  padding-top: 16vh;
  max-width: 660px;
  width: 70vw;
}

.publications-container {
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  font-size: 2.0vh;
  line-height: 3vh;
  height: 40vh;
  max-height: 500px;
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.p-title {
  font-weight: bold;

}

.publications-links:hover {
  color: #dc8960;
  font-weight: bold;

}

.publications-items, a {
  color: #242424;
}

.publications-items {
  margin-bottom: 30px;
}

.publications-container::-webkit-scrollbar {
  display: none;
}

.publications-arrow-wrap {
  position: relative;
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 20px;
}

.publications-arrow {
  /* position: absolute; */
  /* height: 0px; */
  margin: auto;
  font-size: 2.6vh;
}
