body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  position: absolute;
}
