@keyframes fade {
  0% {
    transform: scaleY(1);
    opacity: 1;
  }
  80% {
    transform: scaleY(0.1);
  }
  100% {
    transform: scaleY(0.1);
    opacity: 0.4;
  }
}

@keyframes rotate-keyframes {
  from {
  transform: rotate(0deg);
 }

 to {
  transform: rotate(360deg);
 }
}


.header {
  position: fixed;
  top: 2vh;
  z-index: 999;
}

.menu-img {
  margin-left:20px;
  cursor: pointer;
  height: 2.3vh;
  max-height: 25px;
}

.homepage-img {
  height: 4vh;
  max-height: 35px;
}

.grid-img {
  margin-right:20px;
  height: 2.3vh;
  max-height: 25px;
}
.closing {
  -webkit-animation: fade 0.3s ease-in both normal;
  -moz-animation:    fade 0.3s ease-in both normal;
  -o-animation:      fade 0.3s ease-in both normal;
  -ms-transition:    fade 0.3s ease-in both normal;
  animation:         fade 0.3s ease-in both normal;
}

.opening {
  -webkit-animation: fade 0.6s ease-out both reverse;
  -moz-animation:    fade 0.6s ease-out both reverse;
  -o-animation:      fade 0.6s ease-out both reverse;
  -ms-transition:    fade 0.6s ease-out both reverse;
  animation:         fade 0.6s ease-out both reverse;
}

.rotate {
  -webkit-animation: rotate-keyframes 1.2s;
  -moz-animation:    rotate-keyframes 1.2s;
  -o-animation:      rotate-keyframes 1.2s;
  -ms-transition:    rotate-keyframes 1.2s;
  animation:         rotate-keyframes 1.2s;
}


