.manage {
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 11;
}

.manage-container {
  margin: auto;
  padding-top: 26vh;
  width: 400px;
  text-align: center;
  font-size: 32px;
  line-height: 60px;
}

.manage-container-project {
  margin-bottom: 30px;
}

.manage-items, a {
  color: #242424;
}
.manage-items:hover, .manage-items.active {
  color: #dc8960;
}

