.gridpage{
  /* touch-action: none; */
  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
}

.filters-wrap {
  padding-top: 8vh;
  margin: auto;
  width: 76vw;
}

.filters {
  width: 1000px;
  color: #d3d3d3;
  font-family: arial;
  font-size: 19px;
}

.grid-projects::-webkit-scrollbar, .projects::-webkit-scrollbar {
  display: none;
}

.grid-projects {
  touch-action: pan-x;
  margin-top: 1vh;
  padding-left: 12vw;
  width: 100vw;
  height: 70vh;
  /* overflow-x: auto; */
  overflow-y: hidden; /* Hide vertical scrollbar */
  /* overflow-x: hidden; */
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.projects {
  width: max-content;
  height: 100%;
}
.projects-col, .image-link {
  height: 100%;
}
.projects-col {
  padding-right: 3px;
}

.projects-row {
  height: 100%;
  padding-right: 12vw;
}

.image-row {
  height: 50%;
  padding-bottom: 3px;
}
.image-wrap {
  position: relative;
  /* width: 600px; */
  display: inline-block;
  height: 100%;
  cursor: pointer;
  /* background-color: #e0e0e0; */
}

.image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  /* position: absolute; */
  left: 0;
  top: 0;
  z-index: 10;
  animation: createBox 1s;
}

.image:hover {
  animation: createBoxIn 1s;
  animation-fill-mode: forwards;
}

@keyframes createBox {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes createBoxIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.hover-image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -10;
  /* width: 100%; */
  /* height: 400px; */
  background-color: #e0e0e0;
  animation: createBox 2s;
  color: #242424;
  text-decoration: none;
}

.hover-image-header {
  padding-top: 2px;
  padding-left: 10px;
  font-size: 1.7vh;
}

.hover-image-footer {
  width:100%;
  bottom: 10px;
  position: absolute;
  font-size: 1vh;
}
.cols {
  margin-right: 20px;
}

.grid-row {
  float: right;
}

.filter {
  color: #d3d3d3;
  font-family: arial;
  font-size: 14px;
   cursor:pointer;
   -webkit-touch-callout: none;
   -webkit-user-select: none;
   -khtml-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
}
